(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsProductController', OpsProductController);

    OpsProductController.$inject = ['$timeout', '$rootScope', '$scope', '$state', 'WinAES', 'OpsCarrier', 'Principal', 'OpsCarrierSearch', 'ParseLinks', 'AlertService', 'paginationConstants','$translate'
        ,'uiGridConstants', 'GridState','DataUtils','$uibModal'];

    function OpsProductController ($timeout, $rootScope, $scope, $state, WinAES, OpsCarrier, Principal, OpsCarrierSearch, ParseLinks, AlertService, paginationConstants,$translate,
                                   uiGridConstants, GridState,DataUtils,$uibModal) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;
        $scope.gridId = 170;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.page = 1;
        vm.itemsPerPage = 20;

        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = null;
        vm.currentSearch = null;
        var gridStateObj = {};
        var gridStateCopy = null;
        var gridCopy;
        var pageLoaded = false;
        var filterTimer;
        vm.isAdvanceSearch = false;
        
        function loadAll () {
            if (vm.searchQuery) {
                OpsCarrierSearch.query({
                    query: vm.searchQuery,
                    type: 0,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                filter.type = 0;
                if(gridCopy){
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term != null && angular.isDefined(cf.term)){
                                if(cf.condition){
                                    var fd = column.field;
                                    if(fd.endWith("Str")){
                                        fd = fd.replace("Str", "");
                                    }
                                    if(cf.condition == 128 || cf.condition == 256){ //" < ",
                                        if(column.field == "effectiveDate" || column.field == "expireDate"){
                                            filter[fd + "TO"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else {
                                            filter[fd + "TO"] = cf.term;
                                        }
                                    }
                                    else if(cf.condition == 32 || cf.condition == 64){ //" > ",
                                        if(column.field == "effectiveDate" || column.field == "expireDate"){
                                            filter[fd + "FM"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else{
                                            filter[fd + "FM"] = cf.term;
                                        }
                                    }
                                }
                                else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                OpsCarrier.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                angular.forEach($scope.gridOptions.data, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                })
                vm.isAdvanceSearch = false;
                resize();
                initData();
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }


        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 600){
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;
            $scope.gridOptions.showGridFooter = vm.isAdvanceSearch;
            $scope.gridOptions.multiSelect = vm.isAdvanceSearch;
            $scope.gridOptions.enableSelectAll = vm.isAdvanceSearch;
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN);
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction =  'desc';
            vm.searchQuery = vm.currentSearch = null;
            vm.isAdvanceSearch = false;
            loadAll();
        }

        $scope.advanceSearch = function() {
            var searchParams = {
                isFromCrm: false
            };
            $uibModal.open({
                templateUrl: 'app/entities/ops-carrier/ops-products-dialog.html',
                controller: 'OpsProductsDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.gridOptions.data = results;
                vm.isAdvanceSearch = vm.currentSearch = true;
                $scope.gridOptions.totalItems = results.length;
                angular.forEach($scope.gridOptions.data, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                })
            }, function() {
            });
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: false, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: false,//多行选中
            enableFooterTotalSelected: true,

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuExcel : false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "ops_channel",
            columnDefs: [
                { field: 'id',
                    width: 120,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container ml-5"><div class="btn-group" uib-dropdown dropdown-append-to-body><button id="btn-append-to-body" type="button" class="btn btn-info btn-grid" uib-dropdown-toggle ng-disabled="manageEnterprise.entity.id"><span class="glyphicon glyphicon-option-horizontal pr-3"></span><span class="caret"></span></button><ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="btn-append-to-body"><li><a href="" ng-click="grid.appScope.WAREHOUSE_CHARGE(row.entity.id, \'channel\', row.entity.channel)"><span class="glyphicon glyphicon-cog"></span><span translate="autopsApp.opsCarrier.jsCharge">jsCharge</span></a></li><li role="separator" class="divider"></li><li><a href="" ui-sref="product-channel.copy({id:row.entity.encodeId})"><span class="glyphicon glyphicon-copy"></span><span translate="autopsApp.opsCarrier.copyChannel">copyChannel</span></a></li><li role="separator" class="divider"></li><li><a href="" ui-sref="product-channel.delete({id:row.entity.encodeId})"><span class="glyphicon glyphicon-copy"></span><span translate="entity.action.delete">deleteChannel</span></a></li></ul></div><button type="button" ui-sref="product-channel.edit({id:row.entity.encodeId})" class="btn btn-primary btn-grid mb-5" ng-disabled="opsCarrier.companyId == 0"><span class="glyphicon glyphicon-modal-window pr-3"></span><span class="hidden-xs hidden-sm" translate="entity.action.edit"></span></button></div>'},
                { field: 'channelCode',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsCarrier.channelCode'),
                    cellTemplate: '<span class="grid-text"><a href="" ui-sref="product-channel.edit({id:row.entity.encodeId})">{{row.entity.channelCode}}</a></span>'},
                { field: 'channel',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsCarrier.channel')},
                { field: 'name',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCarrier.detail.title')},
                { field: 'warehouseName',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCarrier.warehouseName')},        
                { field: 'effectiveDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.effectiveDate'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.effectiveDate | date:\'mediumDate\'}}</span>'},
                { field: 'expireDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.expireDate'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.expireDate | date:\'mediumDate\'}}</span>'},
                { field: 'costConfiguration',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCarrier.costConfiguration')},
                { field: 'assistConfiguration',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCarrier.assistConfiguration')},
                { field: 'chargesCode',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCarrier.chargesCode')},
                { field: 'chargeCurrency',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCarrier.chargeCurrency')},
                { field: 'publishedPriceDiscount',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCarrier.stardardDiscount')},
                { field: 'vipOneDiscount',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: "VIP1"},
                { field: 'vipTwoDiscount',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: "VIP2"},
                { field: 'vipThreeDiscount',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: "VIP3"},
                { field: 'sellMarginPercent',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCarrier.sellMarginPercent')},
                { field: 'chargeType',
                    minWidth:150,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.CHARGE_TYPES
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.chargeType'),
                    cellTemplate: '<span class="grid-text"><span class="label label-info" translate="autopsApp.opsCarrier.chargeTypeOptions.{{row.entity.chargeType}}" ng-if="row.entity.chargeType">chargeType</span></span>'},
                { field: 'minWeight',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCarrier.minWeight')},
                { field: 'maxWeight',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCost.maxWeight')},
                { field: 'minWeightOrder',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCarrier.minWeightOrder')},
                { field: 'weightCarry',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCost.weightCarry')},
                { field: 'volCoe',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCost.volCoe')},
                { field: 'volumeShare',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCost.volumeShare')},
                { field: 'fuelSurchare',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCost.fuelSurchare')},
                { field: 'remoteSurcharge',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCost.remoteSurcharge')},
                { field: 'dutyPrepaySurcharge',
                    minWidth:100,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    displayName: $translate.instant('autopsApp.opsCost.dutyPrepaySurcharge')},
                { field: 'singleBilling',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.singleBilling'),
                    cellTemplate: '<span ng-class="row.entity.singleBilling?\'label-success\':\' label-info\'"translate="{{row.entity.singleBilling?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'singleWeight',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCost.singleWeight'),
                    cellTemplate: '<span ng-class="row.entity.singleWeight?\'label-success\':\' label-info\'"translate="{{row.entity.singleWeight?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'skuBilling',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.skuBilling'),
                    cellTemplate: '<span ng-class="row.entity.skuBilling?\'label-success\':\' label-info\'"translate="{{row.entity.skuBilling?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'compareAfterSum',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.compareAfterSum'),
                    cellTemplate: '<span ng-class="row.entity.compareAfterSum?\'label-success\':\' label-info\'"translate="{{row.entity.compareAfterSum?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'carryAfterCompare',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.carryAfterCompare'),
                    cellTemplate: '<span ng-class="row.entity.carryAfterCompare?\'label-success\':\' label-info\'"translate="{{row.entity.carryAfterCompare?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isGroup',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.isGroup'),
                    cellTemplate: '<span ng-class="row.entity.isGroup?\'label-success\':\' label-info\'"translate="{{row.entity.isGroup?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'pubChannel',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.pubChannel'),
                    cellTemplate: '<span ng-class="row.entity.pubChannel?\'label-success\':\' label-info\'"translate="{{row.entity.pubChannel?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isCol',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.isCol'),
                    cellTemplate: '<span ng-class="row.entity.isCol?\'label-success\':\' label-info\'"translate="{{row.entity.isCol?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isUnitPrice',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.isUnitPrice'),
                    cellTemplate: '<span ng-class="row.entity.isUnitPrice?\'label-success\':\' label-info\'"translate="{{row.entity.isUnitPrice?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'lbsWeight',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsCarrier.lbsWeight'),
                    cellTemplate: '<span ng-class="row.entity.lbsWeight?\'label-success\':\' label-info\'"translate="{{row.entity.lbsWeight?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'docUnit',
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsCost.docUnit')},
                { field: 'maxDocWeight',
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsCost.maxDocWeight')},
                { field: 'opName',
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsCarrier.opName')},
                { field: 'addressCode',
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsCarrier.bindLabelChannel')},
                { field: 'serviceDescription',
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsCarrier.bindService')},
                { field: 'labelCode',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCarrier.labelCode')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = $scope.gridId
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "opsChannel";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "opsChannel";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }                    
                    if(pageLoaded){
                        loadAll();
                    }
                });

                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }

        };

        $timeout(function (){
            loadAll();

            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: $scope.gridId
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    loadAll();
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                    }
                }
                loadAll();
            })
        });
        var unsubscribe = $rootScope.$on('autopsApp:opsCarrierUpdate', function(event, result) {
            if(result.id){
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.id == result.id){
                        angular.copy(result, data);
                        data.encodeId = WinAES.encode(data.id.toString());
                    }
                })
            }
            else {
                loadAll();
            }
        });
    }
})();
